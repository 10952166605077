import React, { useState } from "react";
import { Questions } from "../data/Questions";
import { isEqual } from "../methods/IsEqual";
import Divider from '@mui/material/Divider';
import { LinearProgressWithLabel } from "../components/LinearProgressWithLabel";

import {
    Chart as ChartJS,
    RadialLinearScale,
    PointElement,
    LineElement,
    Filler,
    Tooltip,
    Legend,
} from 'chart.js';

ChartJS.register(
    RadialLinearScale,
    PointElement,
    LineElement,
    Filler,
    Tooltip,
    Legend
);

const answerTheQuestons = JSON.parse(localStorage.getItem("answerClient"));
const arrIdTest = JSON.parse(localStorage.getItem('arrIdTests'));

export const ResultPage = () => {

    const rightResponceTestByTopic = [0, 0];
    const falseResponceTestByTopic = [0, 0];
    const idFalseResponceTest = [];

    const handleChange = () => {
        localStorage.removeItem("answerClient");
        localStorage.removeItem('arrIdTest');
        window.location.assign('/');
    }

    const countRightResponceTest = () => {
        let rightRes = 0;

        for (let i = 0; i < arrIdTest.length; i++) {
            if (Questions[arrIdTest[i]].isSingleResponce) {
                if (Questions[arrIdTest[i]].idCorrectAnswer[0] === Number(answerTheQuestons[i].responceClient)) {
                    rightRes += 1;
                    if (Questions[arrIdTest[i]].topic === "Глава 1. Мониторинг эффективности и безопасности ЛС") {
                        rightResponceTestByTopic[0] += 1;
                    } else if (Questions[arrIdTest[i]].topic === "Глава 2. Основы системы управления качеством в аптеке") {
                        rightResponceTestByTopic[1] += 1;
                    }
                }
            } else {
                if (isEqual(Questions[arrIdTest[i]].idCorrectAnswer, answerTheQuestons[i].responceClient)) {
                    rightRes += 1;
                    if (Questions[arrIdTest[i]].topic === "Глава 1. Мониторинг эффективности и безопасности ЛС") {
                        rightResponceTestByTopic[0] += 1;
                    } else if (Questions[arrIdTest[i]].topic === "Глава 2. Основы системы управления качеством в аптеке") {
                        rightResponceTestByTopic[1] += 1;
                    }
                }
            }
        }
        return (rightRes);
    };

    const countFalseResponceTest = () => {
        let falseRes = 0;

        for (let i = 0; i < arrIdTest.length; i++) {
            if (Questions[arrIdTest[i]].isSingleResponce) {
                if (Questions[arrIdTest[i]].idCorrectAnswer[0] !== Number(answerTheQuestons[i].responceClient)) {
                    falseRes += 1;
                    idFalseResponceTest.push(Questions[arrIdTest[i]].id);
                    if (Questions[arrIdTest[i]].topic === "Глава 1. Мониторинг эффективности и безопасности ЛС") {
                        falseResponceTestByTopic[0] += 1;
                    } else if (Questions[arrIdTest[i]].topic === "Глава 2. Основы системы управления качеством в аптеке") {
                        falseResponceTestByTopic[1] += 1;
                    }
                }
            } else {
                if (!isEqual(Questions[arrIdTest[i]].idCorrectAnswer, answerTheQuestons[i].responceClient)) {
                    falseRes += 1;
                    idFalseResponceTest.push(Questions[arrIdTest[i]].id);
                    if (Questions[arrIdTest[i]].topic === "Глава 1. Мониторинг эффективности и безопасности ЛС") {
                        falseResponceTestByTopic[0] += 1;
                    } else if (Questions[arrIdTest[i]].topic === "Глава 2. Основы системы управления качеством в аптеке") {
                        falseResponceTestByTopic[1] += 1;
                    }
                }
            }
        }

        return (falseRes);
    };

    const [rightResponceTest] = useState(countRightResponceTest);
    const [falseResponceTest] = useState(countFalseResponceTest);

    const finalTestScore = Math.round(rightResponceTest / (rightResponceTest + falseResponceTest) * 10);

    const arrIdTestsByTopic = [rightResponceTestByTopic[0] + falseResponceTestByTopic[0],
    rightResponceTestByTopic[1] + falseResponceTestByTopic[1]];


    return (
        <div className="container text-center">
            <div className="row">
                <h1>Tест по безопасности медицинской деятельности в клинике</h1>
                <Divider sx={{ borderBottomWidth: 3, backgroundColor: "#058877" }} />
            </div>

            <div className="row justify-content-center">
                <div className="col-5"><h3>{"Правильных ответов: " + rightResponceTest + "/" + arrIdTest.length}</h3></div>
                <div className="col-2" />
                <div className="col-5"><h3>{"Неправильных ответов: " + falseResponceTest + "/" + arrIdTest.length}</h3></div>
            </div>

            <div className="row align-items-center my-5">
                <div className="row text-center">
                    <p className="text-start fs-6 pb-2">Глава 1 - Мониторинг эффективности и безопасности ЛС</p>
                    <div className="col-1">
                        <p>{rightResponceTestByTopic[0] + '/' + arrIdTestsByTopic[0]}</p>
                    </div>
                    <div className="col-11">
                        <LinearProgressWithLabel value={rightResponceTestByTopic[0] * (100 / arrIdTestsByTopic[0])} />
                    </div>
                </div>

                <div className="row text-center">
                    <p className="text-start fs-6 pb-2">Глава 2 - Основы системы управления качеством в аптеке</p>
                    <div className="col-1">
                        <p>{rightResponceTestByTopic[1] + '/' + arrIdTestsByTopic[1]}</p>
                    </div>
                    <div className="col-11">
                        <LinearProgressWithLabel value={rightResponceTestByTopic[1] * (100 / arrIdTestsByTopic[1])} />
                    </div>
                    <Divider sx={{ borderBottomWidth: 3, marginBottom: 2 }} />
                </div>
            </div>

            <div className="row justify-content-center mb-3">
                <div className="col-12"><h3>{"Ваша итоговая оценка: " + finalTestScore + " баллов"}</h3></div>
            </div>

            <button type="button" className="btn btn-light justify-content-center" style={{ backgroundColor: "#058877", color: "white" }} onClick={handleChange}>Перейти на главную страницу!</button>

            <div className="row text-start">
                <h2>Ошибки:</h2>
                {idFalseResponceTest.map((fal) =>
                    <div className="row">
                        <div className="row">
                            <h3>{"Вопрос: " + Questions[fal].question}</h3>
                        </div>

                        {Questions[fal].isSingleResponce &&
                            <div>
                                <h4>{"Правильный ответ: " + Questions[fal].idCorrectAnswer + ") " + Questions[fal].responseOptions[Questions[fal].idCorrectAnswer].answer}</h4>
                            </div>}

                        {!Questions[fal].isSingleResponce &&
                            <div>
                                <h4>{"Правильный ответ: " + Questions[fal].idCorrectAnswer.map((res) =>
                                    res + ") " + Questions[fal].responseOptions[res].answer)}</h4>
                            </div>}

                        {Questions[fal].isSingleResponce &&
                            <div>
                                <p>{"Ваш ответ: " + answerTheQuestons.filter(res => res.idQuestion === fal)[0].responceClient + ') ' +
                                    Questions[fal].responseOptions[answerTheQuestons.filter(res =>
                                        res.idQuestion === fal)[0].responceClient].answer}</p>
                            </div>
                        }

                        {!Questions[fal].isSingleResponce &&
                            <div>
                                <p>{"Ваш ответ: " + answerTheQuestons.filter(res => res.idQuestion === fal)[0].responceClient.map(
                                    (value) => value + ") " + Questions[fal].responseOptions[value].answer
                                )}</p>
                            </div>
                        }

                        <div>
                            <p />
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};