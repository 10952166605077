import React from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import { StartTestPage } from './pages/StartTestPage';
import { TestPage } from './pages/TestPage';
import { ResultPage } from "./pages/ResultPage";

function App() {
  return (
    <BrowserRouter>
      <div className="container pt-4">
        <Routes>
          <Route path="/" exact element={<StartTestPage />}/>
          <Route path="/test" element={<TestPage />}/>
          <Route path="/result" element={<ResultPage />}/>
          <Route path = '*' element={<Navigate replace to='/' />} />
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;
