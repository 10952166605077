
export const Questions = [

    {
        "id": 0,
        "topic": "Глава 1. Мониторинг эффективности и безопасности ЛС",
        "roleTest": "NO_ROLE",
        "question": "Комплекс мер по обеспечению эффективности и безопасности фармацевтических продуктов, а также их соответствия официальным требованиям по показателям качества это:",
        "responseOptions": [
            {
                "id": 0,
                "answer": "Система обеспечения качества"
            },
            {
                "id": 1,
                "answer": "Система контроля качества"
            },
            {
                "id": 2,
                "answer": "Система менеджмента качества"
            },
            {
                "id": 3,
                "answer": "Система фармаконадзора"
            }
        ],
        "isSingleResponce": true,
        "idCorrectAnswer": [
            0
        ]
    },
    {
        "id": 1,
        "topic": "Глава 1. Мониторинг эффективности и безопасности ЛС",
        "roleTest": "NO_ROLE",
        "question": "Выберите виды государственного контроля при обращении лекарственных средств:",
        "responseOptions": [
            {
                "id": 0,
                "answer": "Обеспечение качества ЛС"
            },
            {
                "id": 1,
                "answer": "Контроль качества ЛС"
            },
            {
                "id": 2,
                "answer": "Лицензирование фармацевтической деятельности"
            },
            {
                "id": 3,
                "answer": "Выдача разрешений на вывоз лекарственных средств с территории РФ"
            }
        ],
        "isSingleResponce": false,
        "idCorrectAnswer": [
            0,
            1,
            2
        ]
    },
    {
        "id": 2,
        "topic": "Глава 1. Мониторинг эффективности и безопасности ЛС",
        "roleTest": "NO_ROLE",
        "question": "Государственный контроль при обращении ЛС включает контроль за:",
        "responseOptions": [
            {
                "id": 0,
                "answer": "Патентованием способов назначения ЛС"
            },
            {
                "id": 1,
                "answer": "Отпуском ЛС"
            },
            {
                "id": 2,
                "answer": "Применением ЛС"
            },
            {
                "id": 3,
                "answer": "Производством ЛС"
            }
        ],
        "isSingleResponce": false,
        "idCorrectAnswer": [
            1,
            2,
            3
        ]
    },
    {
        "id": 3,
        "topic": "Глава 1. Мониторинг эффективности и безопасности ЛС",
        "roleTest": "NO_ROLE",
        "question": "Выберите основные направления контрольно-разрешительной системы:",
        "responseOptions": [
            {
                "id": 0,
                "answer": "Экспертиза и регистрация ЛС"
            },
            {
                "id": 1,
                "answer": "Стандартизация, сертификация и метрология ЛС"
            },
            {
                "id": 2,
                "answer": "Контроль качества, эффективности и безопасности ЛС"
            },
            {
                "id": 3,
                "answer": "Все ответы верны"
            }
        ],
        "isSingleResponce": true,
        "idCorrectAnswer": [
            3
        ]
    },
    {
        "id": 4,
        "topic": "Глава 1. Мониторинг эффективности и безопасности ЛС",
        "roleTest": "NO_ROLE",
        "question": "Государственной регистрации подлежат (выберите 1 ответ):",
        "responseOptions": [
            {
                "id": 0,
                "answer": "ЛП, приобретенные физическими лицами за пределами территории"
            },
            {
                "id": 1,
                "answer": "Российской Федерации и предназначенные для личного использования;"
            },
            {
                "id": 2,
                "answer": "ЛП, изготовленные аптечными организациями, которые имеют лицензию на фармацевтическую деятельность"
            },
            {
                "id": 3,
                "answer": "Оригинальные лекарственные препараты"
            },
            {
                "id": 4,
                "answer": "Различные ЛП под одинаковым торговым наименованием."
            }
        ],
        "isSingleResponce": true,
        "idCorrectAnswer": [
            3
        ]
    },
    {
        "id": 5,
        "topic": "Глава 1. Мониторинг эффективности и безопасности ЛС",
        "roleTest": "NO_ROLE",
        "question": "Получение сообщений о неблагоприятных побочных реакциях (НПР) лекарственных средств осуществляется посредством:",
        "responseOptions": [
            {
                "id": 0,
                "answer": "Спонтанных сообщений"
            },
            {
                "id": 1,
                "answer": "Стимулированных сообщений"
            },
            {
                "id": 2,
                "answer": "Активного мониторинга"
            },
            {
                "id": 3,
                "answer": "Все ответы верны"
            }
        ],
        "isSingleResponce": true,
        "idCorrectAnswer": [
            3
        ]
    },
    {
        "id": 6,
        "topic": "Глава 1. Мониторинг эффективности и безопасности ЛС",
        "roleTest": "NO_ROLE",
        "question": "При развитии серьезных и непредвиденных НПР на ЛС на территории РФ информация направляется одновременно в территориальное управление Росздравнадзора по субъекту РФ и в Федеральный центр мониторинга безопасности лекарственных средств в срок:",
        "responseOptions": [
            {
                "id": 0,
                "answer": "Не позже 10 рабочих дней с момента получения информации"
            },
            {
                "id": 1,
                "answer": "Не позднее 5 рабочих дней с момента получения информации"
            },
            {
                "id": 2,
                "answer": "Не позднее 15 рабочих дней с момента получения информации"
            },
            {
                "id": 3,
                "answer": "В течение 1 рабочего дня с момента получения информации"
            }
        ],
        "isSingleResponce": true,
        "idCorrectAnswer": [
            2
        ]
    },
    {
        "id": 7,
        "topic": "Глава 1. Мониторинг эффективности и безопасности ЛС",
        "roleTest": "NO_ROLE",
        "question": "О серьезных непредвиденных подозреваемых НПР, выявленных за рубежом, рекомендуется сообщать в срок:",
        "responseOptions": [
            {
                "id": 0,
                "answer": "Не позже 10 дней с момента получения информации;"
            },
            {
                "id": 1,
                "answer": "В течение 1 рабочего дня с момента получения информации"
            },
            {
                "id": 2,
                "answer": "Не позднее 5 рабочих дней с момента получения информации"
            },
            {
                "id": 3,
                "answer": "Все ответы неправильные"
            }
        ],
        "isSingleResponce": true,
        "idCorrectAnswer": [
            0
        ]
    },
    {
        "id": 8,
        "topic": "Глава 1. Мониторинг эффективности и безопасности ЛС",
        "roleTest": "NO_ROLE",
        "question": "В первые 2 года после международной даты рождения ЛС периодический отчет по безопасности представляется:",
        "responseOptions": [
            {
                "id": 0,
                "answer": "Каждые 6 месяцев"
            },
            {
                "id": 1,
                "answer": "Каждые 3 месяца"
            },
            {
                "id": 2,
                "answer": "Каждый год"
            },
            {
                "id": 3,
                "answer": "Каждый месяц"
            }
        ],
        "isSingleResponce": true,
        "idCorrectAnswer": [
            0
        ]
    },
    {
        "id": 9,
        "topic": "Глава 1. Мониторинг эффективности и безопасности ЛС",
        "roleTest": "NO_ROLE",
        "question": "Какие меры могут быть приняты в связи с проблемами безопасности ЛС:",
        "responseOptions": [
            {
                "id": 0,
                "answer": "Отзыв или приостановка действия регистрации"
            },
            {
                "id": 1,
                "answer": "Приостановка клинических исследований"
            },
            {
                "id": 2,
                "answer": "Изменение дозировок"
            },
            {
                "id": 3,
                "answer": "Все ответы верны"
            }
        ],
        "isSingleResponce": true,
        "idCorrectAnswer": [
            3
        ]
    },
    {
        "id": 10,
        "topic": "Глава 1. Мониторинг эффективности и безопасности ЛС",
        "roleTest": "NO_ROLE",
        "question": "Основной целью контрольно-разрешительной системы РФ является:",
        "responseOptions": [
            {
                "id": 0,
                "answer": "Контроль качества лекарственных средств"
            },
            {
                "id": 1,
                "answer": "Лицензирование фармацевтической деятельности и производства лекарственных средств"
            },
            {
                "id": 2,
                "answer": "Защита потребителей от негативных последствий применения лекарственных средств, связанных с недостаточной изученностью на этапе разрешения и внедрения в практику, выпуском и ввозом в страну недоброкачественной продукции, нарушениями условий хранения и реализации"
            },
            {
                "id": 3,
                "answer": "Проведение проверок субъектами обращения лекарственных средств правил лабораторной и клинической практики"
            }
        ],
        "isSingleResponce": true,
        "idCorrectAnswer": [
            2
        ]
    },
    {
        "id": 11,
        "topic": "Глава 1. Мониторинг эффективности и безопасности ЛС",
        "roleTest": "NO_ROLE",
        "question": "Контрольно-разрешительная система РФ была создана на основании приказа:",
        "responseOptions": [
            {
                "id": 0,
                "answer": "№ 61-ФЗ от 12.04.2010 г."
            },
            {
                "id": 1,
                "answer": "№ 149 от 28.06.1993 г."
            },
            {
                "id": 2,
                "answer": "№ 323-ФЗ от 21.11.2011г."
            },
            {
                "id": 3,
                "answer": "№ 2300-1 от 07.02.1992 г."
            }
        ],
        "isSingleResponce": true,
        "idCorrectAnswer": [
            1
        ]
    },
    {
        "id": 12,
        "topic": "Глава 1. Мониторинг эффективности и безопасности ЛС",
        "roleTest": "NO_ROLE",
        "question": "Основными разделами регистрационного досье являются:",
        "responseOptions": [
            {
                "id": 0,
                "answer": "Качество"
            },
            {
                "id": 1,
                "answer": "Количественное определение"
            },
            {
                "id": 2,
                "answer": "Эффективность"
            },
            {
                "id": 3,
                "answer": "Безопасность"
            },
            {
                "id": 4,
                "answer": "Валидация"
            },
            {
                "id": 5,
                "answer": "Резюме досье"
            }
        ],
        "isSingleResponce": false,
        "idCorrectAnswer": [
            0,
            1,
            2,
            3,
            4,
            5
        ]
    },
    {
        "id": 13,
        "topic": "Глава 1. Мониторинг эффективности и безопасности ЛС",
        "roleTest": "NO_ROLE",
        "question": "Правовой основой проведения клинических испытаний лекарственного препарата является:",
        "responseOptions": [
            {
                "id": 0,
                "answer": "Приказ № 149 от 28.06.1993 г."
            },
            {
                "id": 1,
                "answer": "Постановление Правительства РФ N 1081 от 22.12.2011 г."
            },
            {
                "id": 2,
                "answer": "Федеральный закон РФ № 61-ФЗ от 12.04.2010 г."
            },
            {
                "id": 3,
                "answer": "Федеральный закон N 184-ФЗ от 27.12.2002 г."
            }
        ],
        "isSingleResponce": true,
        "idCorrectAnswer": [
            2
        ]
    },
    {
        "id": 14,
        "topic": "Глава 1. Мониторинг эффективности и безопасности ЛС",
        "roleTest": "NO_ROLE",
        "question": "Верно ли утверждение, что согласно ФЗ № 61 проведение экспертизы ЛС в рамках государственной регистрации возложено на ФГБУ «Научный центр экспертизы средств медицинского применения»",
        "responseOptions": [
            {
                "id": 0,
                "answer": "Верно"
            },
            {
                "id": 1,
                "answer": "Неверно"
            }
        ],
        "isSingleResponce": true,
        "idCorrectAnswer": [
            0
        ]
    },
    {
        "id": 15,
        "topic": "Глава 1. Мониторинг эффективности и безопасности ЛС",
        "roleTest": "NO_ROLE",
        "question": "Безопасность лекарственного средства:",
        "responseOptions": [
            {
                "id": 0,
                "answer": "Это реакция организма, возникшая в связи с применением лекарственного препарата в дозах, рекомендуемых в инструкции по его применению, для профилактики, диагностики, лечения заболевания или для реабилитации"
            },
            {
                "id": 1,
                "answer": "Это преднамеренная благоприятная реакция организма, связанная с применением лекарственного препарата"
            },
            {
                "id": 2,
                "answer": "Это свойство лекарственного средства, обусловленное наличием рисков, связанных с его применением"
            },
            {
                "id": 3,
                "answer": "Это способность лекарственного средства, используемая для профилактики, диагностики, лечения заболевания, реабилитации, для сохранения, предотвращения или прерывания беременности"
            },
            {
                "id": 4,
                "answer": "Это характеристика лекарственного средства, основанная на сравнительном анализе его эффективности и риска причинения вреда здоровью"
            }
        ],
        "isSingleResponce": true,
        "idCorrectAnswer": [
            4
        ]
    },
    {
        "id": 16,
        "topic": "Глава 1. Мониторинг эффективности и безопасности ЛС",
        "roleTest": "NO_ROLE",
        "question": "Действие лекарственного препарата в токсических дозах:",
        "responseOptions": [
            {
                "id": 0,
                "answer": "Побочное действие"
            },
            {
                "id": 1,
                "answer": "Передозировка (интоксикация)"
            },
            {
                "id": 2,
                "answer": "Главное действие"
            },
            {
                "id": 3,
                "answer": "Нежелательное явление"
            },
            {
                "id": 4,
                "answer": "Нежелательная реакция"
            },
            {
                "id": 5,
                "answer": "Гомеопатическое действие"
            }
        ],
        "isSingleResponce": true,
        "idCorrectAnswer": [
            1
        ]
    },
    {
        "id": 17,
        "topic": "Глава 1. Мониторинг эффективности и безопасности ЛС",
        "roleTest": "NO_ROLE",
        "question": "Низкая градация степени достоверности причинно-следственной связи, это:",
        "responseOptions": [
            {
                "id": 0,
                "answer": "Определенно связано"
            },
            {
                "id": 1,
                "answer": "Возможно связано"
            },
            {
                "id": 2,
                "answer": "Недостаточно информации"
            },
            {
                "id": 3,
                "answer": "Вероятно, связано"
            }
        ],
        "isSingleResponce": true,
        "idCorrectAnswer": [
            2
        ]
    },
    {
        "id": 18,
        "topic": "Глава 1. Мониторинг эффективности и безопасности ЛС",
        "roleTest": "NO_ROLE",
        "question": "Метод оценки степени достоверности причинно-следственной связи, это:",
        "responseOptions": [
            {
                "id": 0,
                "answer": "Метод «Шкала Наранжо»"
            },
            {
                "id": 1,
                "answer": "Метод спонтанных сообщений"
            },
            {
                "id": 2,
                "answer": "Метод стимулированных сообщений"
            },
            {
                "id": 3,
                "answer": "Активное мониторирование НР"
            },
            {
                "id": 4,
                "answer": "Когортные исследования"
            },
            {
                "id": 5,
                "answer": "Мониторинг выписанных рецептов"
            },
            {
                "id": 6,
                "answer": "Учет всех медицинских записей о пациенте"
            }
        ],
        "isSingleResponce": true,
        "idCorrectAnswer": [
            0
        ]
    },
    {
        "id": 19,
        "topic": "Глава 1. Мониторинг эффективности и безопасности ЛС",
        "roleTest": "NO_ROLE",
        "question": "Нежелательная реакция типа «А»:",
        "responseOptions": [
            {
                "id": 0,
                "answer": "Аллергия"
            },
            {
                "id": 1,
                "answer": "Идиосинкразия"
            },
            {
                "id": 2,
                "answer": "Органотоксичность"
            },
            {
                "id": 3,
                "answer": "Подавление выработки эндогенных веществ"
            },
            {
                "id": 4,
                "answer": "Нет правильного варианта ответа"
            }
        ],
        "isSingleResponce": true,
        "idCorrectAnswer": [
            2
        ]
    },
    {
        "id": 20,
        "topic": "Глава 1. Мониторинг эффективности и безопасности ЛС",
        "roleTest": "NO_ROLE",
        "question": "Нежелательная реакция типа «В»:",
        "responseOptions": [
            {
                "id": 0,
                "answer": "Избыточные фармакологические эффекты"
            },
            {
                "id": 1,
                "answer": "Идиосинкразия"
            },
            {
                "id": 2,
                "answer": "Органотоксичность"
            },
            {
                "id": 3,
                "answer": "Кумуляция"
            },
            {
                "id": 4,
                "answer": "Зависимость"
            },
            {
                "id": 5,
                "answer": "Подавление выработки эндогенных веществ"
            },
            {
                "id": 6,
                "answer": "Канцерогенность"
            },
            {
                "id": 7,
                "answer": "Мутагенность, тератогенность"
            }
        ],
        "isSingleResponce": true,
        "idCorrectAnswer": [
            1
        ]
    },
    {
        "id": 21,
        "topic": "Глава 1. Мониторинг эффективности и безопасности ЛС",
        "roleTest": "NO_ROLE",
        "question": "Метод выявления нежелательных реакций типа «D»:",
        "responseOptions": [
            {
                "id": 0,
                "answer": "Метод спонтанных сообщений"
            },
            {
                "id": 1,
                "answer": "Когортные исследования"
            },
            {
                "id": 2,
                "answer": "Экспериментальные исследования на животных"
            },
            {
                "id": 3,
                "answer": "Мониторинг выписываемых рецептов"
            },
            {
                "id": 4,
                "answer": "Исследования типа «случай-контроль»"
            },
            {
                "id": 5,
                "answer": "Данные регистров заболеваний"
            },
            {
                "id": 6,
                "answer": "Долговременный мониторинг выписываемых рецептов"
            }
        ],
        "isSingleResponce": true,
        "idCorrectAnswer": [
            4
        ]
    },
    {
        "id": 22,
        "topic": "Глава 1. Мониторинг эффективности и безопасности ЛС",
        "roleTest": "NO_ROLE",
        "question": "Метод выявления сигналов по безопасности лекарственного препарата, это (укажите один правильный ответ):",
        "responseOptions": [
            {
                "id": 0,
                "answer": "«Метод троек»"
            },
            {
                "id": 1,
                "answer": "Метод «TURBO–модель»"
            },
            {
                "id": 2,
                "answer": "Quantitative Framework for Risk and Benefit Assessment (QFRBA)"
            },
            {
                "id": 3,
                "answer": "Benefit-less-risk analysis (BLRA)"
            },
            {
                "id": 4,
                "answer": "Анализ административных решений регуляторных органов"
            }
        ],
        "isSingleResponce": true,
        "idCorrectAnswer": [
            4
        ]
    },
    {
        "id": 23,
        "topic": "Глава 1. Мониторинг эффективности и безопасности ЛС",
        "roleTest": "NO_ROLE",
        "question": "Международная база данных с сообщениями о случаях нежелательных реакций лекарственных средств:",
        "responseOptions": [
            {
                "id": 0,
                "answer": "АИС Росздравнадзор Фармаконадзор"
            },
            {
                "id": 1,
                "answer": "МКИЛС"
            },
            {
                "id": 2,
                "answer": "Единая база данных ЕАЭС"
            },
            {
                "id": 3,
                "answer": "СНЯ КИБЛС"
            },
            {
                "id": 4,
                "answer": "ГРЛС"
            },
            {
                "id": 5,
                "answer": "База данных Роспотребнадзора"
            }
        ],
        "isSingleResponce": true,
        "idCorrectAnswer": [
            0
        ]
    },
    {
        "id": 24,
        "topic": "Глава 1. Мониторинг эффективности и безопасности ЛС",
        "roleTest": "NO_ROLE",
        "question": "Непредвиденная нежелательная реакция, это:",
        "responseOptions": [
            {
                "id": 0,
                "answer": "Нежелательная реакция организма, связанная с применением лекарственного препарата, приведшая к смерти, врожденным аномалиям или порокам развития либо представляющая собой угрозу жизни, требующая госпитализации или приведшая к стойкой утрате трудоспособности и (или) инвалидности "
            },
            {
                "id": 1,
                "answer": "Нежелательная реакция организма, сущность, тяжесть или исход которой не соответствует информации о лекарственном препарате, содержащейся в протоколе его клинического исследования, брошюре исследователя или в инструкции по применению лекарственного препарата;"
            },
            {
                "id": 2,
                "answer": "Реакция организма, возникшая в связи с применением лекарственного препарата в дозах, рекомендуемых в инструкции по его применению, для профилактики, диагностики, лечения заболевания или для реабилитации"
            },
            {
                "id": 3,
                "answer": "Подробное описание мероприятий по фармаконадзору, направленных на выявление, оценку и предотвращение или минимизацию рисков, связанных с лекарственными препаратами, включая оценку эффективности данных мероприятий"
            }
        ],
        "isSingleResponce": false,
        "idCorrectAnswer": [
            1,
            2
        ]
    },
    {
        "id": 25,
        "topic": "Глава 2. Основы системы управления качеством в аптеке",
        "roleTest": "NO_ROLE",
        "question": "В иерархии документации системы управления качеством в аптеке главным документом должен быть",
        "responseOptions": [
            {
                "id": 0,
                "answer": "политика в области качества;"
            },
            {
                "id": 1,
                "answer": "регистрирующие документы;"
            },
            {
                "id": 2,
                "answer": "руководство по качеству;"
            },
            {
                "id": 3,
                "answer": "технологическая документация."
            }
        ],
        "isSingleResponce": true,
        "idCorrectAnswer": [
            0
        ]
    },
    {
        "id": 26,
        "topic": "Глава 2. Основы системы управления качеством в аптеке",
        "roleTest": "NO_ROLE",
        "question": "В соответствии со стандартами ISO серии 9000:2000 качество – это",
        "responseOptions": [
            {
                "id": 0,
                "answer": "отличные характеристики продукции и отсутствие жалоб потребителя;"
            },
            {
                "id": 1,
                "answer": "соответствие процесса или продукции нормативной документации;"
            },
            {
                "id": 2,
                "answer": "степень соответствия характеристик продукции, процесса или системы существующим, или предполагаемым потребностям;"
            },
            {
                "id": 3,
                "answer": "то, что удовлетворяет потребителя."
            }
        ],
        "isSingleResponce": true,
        "idCorrectAnswer": [
            2
        ]
    },
    {
        "id": 27,
        "topic": "Глава 2. Основы системы управления качеством в аптеке",
        "roleTest": "NO_ROLE",
        "question": "Валидацией называются",
        "responseOptions": [
            {
                "id": 0,
                "answer": "действия, приводящие к высокой степени уверенности в том, что методика, процесс, процедура выполнены по заранее установленным критериям приемлемости;"
            },
            {
                "id": 1,
                "answer": "действия, регистрирующие выполнение методики, процесса, работы с оборудованием, материалами, операциями или системами;"
            },
            {
                "id": 2,
                "answer": "документально оформленные действия персонала, дающие высокую степень уверенности в том, что порученная им работа была выполнена надлежащим образом;"
            },
            {
                "id": 3,
                "answer": "документально оформленные действия, дающие высокую степень уверенности в том, что методика, процесс, оборудование, материал, операция или система соответствуют заданным требованиям и их использование будет постоянно приводить к результатам, соответствующим заранее установленным критериям приемлемости."
            }
        ],
        "isSingleResponce": true,
        "idCorrectAnswer": [
            3
        ]
    },
    {
        "id": 28,
        "topic": "Глава 2. Основы системы управления качеством в аптеке",
        "roleTest": "NO_ROLE",
        "question": "Внедрение системы управления качеством в аптечной организации должно начинаться",
        "responseOptions": [
            {
                "id": 0,
                "answer": "выбором методов для управления качеством процессов;"
            },
            {
                "id": 1,
                "answer": "выбором методов достоверной оценки показателей качества выполнения процессов;"
            },
            {
                "id": 2,
                "answer": "разработкой системы аттестации персонала в сфере управления качеством в аптечной организации;"
            },
            {
                "id": 3,
                "answer": "специальным обучением персонала, ответственным за внедрение и эффективную работу системы управления качеством."
            }
        ],
        "isSingleResponce": true,
        "idCorrectAnswer": [
            3
        ]
    },
    {
        "id": 29,
        "topic": "Глава 2. Основы системы управления качеством в аптеке",
        "roleTest": "NO_ROLE",
        "question": "Входит ли аудит поставщиков в полномочия Уполномоченного по качеству аптечной организации?",
        "responseOptions": [
            {
                "id": 0,
                "answer": "в соответствии с договором;"
            },
            {
                "id": 1,
                "answer": "входит;"
            },
            {
                "id": 2,
                "answer": "не входит;"
            },
            {
                "id": 3,
                "answer": "при разрешении поставщика."
            }
        ],
        "isSingleResponce": true,
        "idCorrectAnswer": [
            1
        ]
    },
    {
        "id": 30,
        "topic": "Глава 2. Основы системы управления качеством в аптеке",
        "roleTest": "NO_ROLE",
        "question": "Входит ли в задачи системы управления качеством в аптеке установление последовательности и взаимодействия процессов, необходимых для обеспечения системы качества, в зависимости от их влияния на безопасность, эффективность и рациональность применения лекарственных препаратов?",
        "responseOptions": [
            {
                "id": 0,
                "answer": "входит обязательно;"
            },
            {
                "id": 1,
                "answer": "входит только в случае доказанной последовательности и взаимодействия процессов, необходимых для обеспечения системы качества;"
            },
            {
                "id": 2,
                "answer": "не входит, потому что аптека не рассматривается как система;"
            },
            {
                "id": 3,
                "answer": "не входит, так как между процессами и качеством нет взаимосвязи."
            }
        ],
        "isSingleResponce": true,
        "idCorrectAnswer": [
            0
        ]
    },
    {
        "id": 31,
        "topic": "Глава 2. Основы системы управления качеством в аптеке",
        "roleTest": "NO_ROLE",
        "question": "Должен ли провизор осуществлять консультирование и информирование посетителя аптеки по правилам льготного отпуска лекарственных средств?",
        "responseOptions": [
            {
                "id": 0,
                "answer": "должен в полном объеме;"
            },
            {
                "id": 1,
                "answer": "должен, но только в том случае, если посетитель запросит данную информацию;"
            },
            {
                "id": 2,
                "answer": "не должен, так как это функция лечащего врача;"
            },
            {
                "id": 3,
                "answer": "не должен, так как это функция органов социальной защиты населения."
            }
        ],
        "isSingleResponce": true,
        "idCorrectAnswer": [
            0
        ]
    },
    {
        "id": 32,
        "topic": "Глава 2. Основы системы управления качеством в аптеке",
        "roleTest": "NO_ROLE",
        "question": "За качество реализуемых товаров аптечного ассортимента несет ответственность",
        "responseOptions": [
            {
                "id": 0,
                "answer": "аптечная организация;"
            },
            {
                "id": 1,
                "answer": "провизор, отпускающий товар покупателю;"
            },
            {
                "id": 2,
                "answer": "производитель товаров;"
            },
            {
                "id": 3,
                "answer": "транспортная компания."
            }
        ],
        "isSingleResponce": true,
        "idCorrectAnswer": [
            0
        ]
    },
    {
        "id": 33,
        "topic": "Глава 2. Основы системы управления качеством в аптеке",
        "roleTest": "NO_ROLE",
        "question": "Идеи и положения теории всеобщего менеджмента качества изложены в стандартах",
        "responseOptions": [
            {
                "id": 0,
                "answer": "ISO серии 6000;"
            },
            {
                "id": 1,
                "answer": "ISO серии 7000;"
            },
            {
                "id": 2,
                "answer": "ISO серии 8000;"
            },
            {
                "id": 3,
                "answer": "ISO серии 9000."
            }
        ],
        "isSingleResponce": true,
        "idCorrectAnswer": [
            3
        ]
    },
    {
        "id": 34,
        "topic": "Глава 2. Основы системы управления качеством в аптеке",
        "roleTest": "NO_ROLE",
        "question": "Имеет ли право Уполномоченный по качеству аптечной организации инициировать проверку надлежащей эксплуатации помещений, оборудования, ведения документации системы управления качеством в аптеке на соответствие с утвержденными нормам и правилам?",
        "responseOptions": [
            {
                "id": 0,
                "answer": "имеет;"
            },
            {
                "id": 1,
                "answer": "имеет, если есть предписание уполномоченной надзорной организации;"
            },
            {
                "id": 2,
                "answer": "имеет, если персонал аптечной организации согласен на проверку;"
            },
            {
                "id": 3,
                "answer": "не имеет."
            }
        ],
        "isSingleResponce": true,
        "idCorrectAnswer": [
            0
        ]
    },
    {
        "id": 35,
        "topic": "Глава 2. Основы системы управления качеством в аптеке",
        "roleTest": "NO_ROLE",
        "question": "К внешним субъектам управления качеством работы аптечной организации относятся",
        "responseOptions": [
            {
                "id": 0,
                "answer": "государственные контрольно-надзорные органы;"
            },
            {
                "id": 1,
                "answer": "законодательные органы;"
            },
            {
                "id": 2,
                "answer": "руководитель организации;"
            },
            {
                "id": 3,
                "answer": "центры сертификации."
            }
        ],
        "isSingleResponce": false,
        "idCorrectAnswer": [
            0,
            1,
            3
        ]
    },
    {
        "id": 36,
        "topic": "Глава 2. Основы системы управления качеством в аптеке",
        "roleTest": "NO_ROLE",
        "question": "К внутренним субъектам управления качеством работы аптечной организации относятся",
        "responseOptions": [
            {
                "id": 0,
                "answer": "Росздравнадзор;"
            },
            {
                "id": 1,
                "answer": "руководитель организации;"
            },
            {
                "id": 2,
                "answer": "уполномоченный по качеству аптечной организации;"
            },
            {
                "id": 3,
                "answer": "центры сертификации."
            }
        ],
        "isSingleResponce": false,
        "idCorrectAnswer": [
            1,
            2
        ]
    },
    {
        "id": 37,
        "topic": "Глава 2. Основы системы управления качеством в аптеке",
        "roleTest": "NO_ROLE",
        "question": "К задачам самоинспекции в аптечной организации относятся",
        "responseOptions": [
            {
                "id": 0,
                "answer": "дисциплинарные воздействия в отношении персонала;"
            },
            {
                "id": 1,
                "answer": "инспекция вопросов качества аптечной организации или его отдельного подразделения в части выполнения лицензионных требований работы персонала;"
            },
            {
                "id": 2,
                "answer": "эксплуатации помещений, зданий и оборудования; процессов и стандартных операционных процедур; ведения документооборота и т.д. на соответствие установленным нормам;"
            },
            {
                "id": 3,
                "answer": "проведение корректирующих мероприятий по устранению выявленных отклонений от установленных норм;"
            },
            {
                "id": 4,
                "answer": "усовершенствование системы управления качеством работы предприятия на основании результатов проведенной самоинспекции."
            }
        ],
        "isSingleResponce": false,
        "idCorrectAnswer": [
            1,
            2,
            3,
            4
        ]
    },
    {
        "id": 38,
        "topic": "Глава 2. Основы системы управления качеством в аптеке",
        "roleTest": "NO_ROLE",
        "question": "К международным стандартам надлежащих практик в области фармацевтической деятельности относятся",
        "responseOptions": [
            {
                "id": 0,
                "answer": "надлежащая аптечная практика (Good Pharmacy Practice);"
            },
            {
                "id": 1,
                "answer": "надлежащая дистрибьюторская практика (Good Distribution Practice);"
            },
            {
                "id": 2,
                "answer": "надлежащая лабораторная практика (Good Laboratory Practice);"
            },
            {
                "id": 3,
                "answer": "надлежащая практика хранения фармацевтической продукции (Guide to good storage practices for pharmaceuticals)."
            }
        ],
        "isSingleResponce": false,
        "idCorrectAnswer": [
            0,
            1,
            3
        ]
    },
    {
        "id": 39,
        "topic": "Глава 2. Основы системы управления качеством в аптеке",
        "roleTest": "NO_ROLE",
        "question": "К объектам управления качеством относятся",
        "responseOptions": [
            {
                "id": 0,
                "answer": "персонал;"
            },
            {
                "id": 1,
                "answer": "помещения и здания;"
            },
            {
                "id": 2,
                "answer": "потребитель;"
            },
            {
                "id": 3,
                "answer": "процессы."
            }
        ],
        "isSingleResponce": false,
        "idCorrectAnswer": [
            0,
            1,
            3
        ]
    },
    {
        "id": 40,
        "topic": "Глава 2. Основы системы управления качеством в аптеке",
        "roleTest": "NO_ROLE",
        "question": "К принципам надлежащей аптечной практики относятся",
        "responseOptions": [
            {
                "id": 0,
                "answer": "обеспечение населения лекарственными средствами и другими товарами аптечного ассортимента;"
            },
            {
                "id": 1,
                "answer": "постановка диагноза;"
            },
            {
                "id": 2,
                "answer": "регистрация нежелательных побочных явлений при применении лекарственных препаратов и информирование надзорных органов о зарегистрированных фактах (фармаконадзор);"
            },
            {
                "id": 3,
                "answer": "фармацевтическое консультирование и информирование пациентов и медицинских работников."
            }
        ],
        "isSingleResponce": false,
        "idCorrectAnswer": [0, 2, 3]
    },
    {
        "id": 41,
        "topic": "Глава 2. Основы системы управления качеством в аптеке",
        "roleTest": "NO_ROLE",
        "question": "К регистрирующим документам системы управления качества в аптеке относятся",
        "responseOptions": [
            {
                "id": 0,
                "answer": "журналы;"
            },
            {
                "id": 1,
                "answer": "инструкции выполнения стандартных операционных процедур;"
            },
            {
                "id": 2,
                "answer": "распоряжения и приказы руководства;"
            },
            {
                "id": 3,
                "answer": "стеллажные карты."
            }
        ],
        "isSingleResponce": false,
        "idCorrectAnswer": [0, 3]
    },
    {
        "id": 42,
        "topic": "Глава 2. Основы системы управления качеством в аптеке",
        "roleTest": "NO_ROLE",
        "question": "Качеством труда работника является",
        "responseOptions": [
            {
                "id": 0,
                "answer": "выполнение работы в установленные сроки;"
            },
            {
                "id": 1,
                "answer": "степень соответствия его выполнения нормативным требованиям, установленным для его проведения;"
            },
            {
                "id": 2,
                "answer": "степень соответствия компетенциям, требованиям к выполнению трудовых функций, должностных инструкций и других норм, предусмотренных профессиональными стандартами и трудовым законодательством;"
            },
            {
                "id": 3,
                "answer": "степень соответствия оказания услуги нормативным или договорным требованиям, утвержденным для данной услуги."
            }
        ],
        "isSingleResponce": true,
        "idCorrectAnswer": [2]
    },
    {
        "id": 43,
        "topic": "Глава 2. Основы системы управления качеством в аптеке",
        "roleTest": "NO_ROLE",
        "question": "Лицензионный контроль в сфере фармацевтической деятельности осуществляется на основании",
        "responseOptions": [
            {
                "id": 0,
                "answer": "Федерального закона от 21.11.2011 № 323-ФЗ «Об основах охраны здоровья граждан в Российской Федерации»;"
            },
            {
                "id": 1,
                "answer": "Федерального закона от 26 декабря 2008 года № 294-ФЗ «О защите прав юридических лиц и индивидуальных предпринимателей при осуществлении государственного контроля (надзора) и муниципального контроля»; "
            },
            {
                "id": 2,
                "answer": "Федерального закона от 27.12.2002 № 184-ФЗ «О техническом регулировании»;"
            },
            {
                "id": 3,
                "answer": "Федерального закона от 4 мая 2011 года № 99-ФЗ «О лицензировании отдельных видов деятельности»."
            }
        ],
        "isSingleResponce": false,
        "idCorrectAnswer": [1, 3]
    },
    {
        "id": 44,
        "topic": "Глава 2. Основы системы управления качеством в аптеке",
        "roleTest": "NO_ROLE",
        "question": "Лицом, ответственным за внедрение и обеспечение системы качества в аптечной организации, назначаемым администрацией является",
        "responseOptions": [
            {
                "id": 0,
                "answer": "провизор;"
            },
            {
                "id": 1,
                "answer": "провизор-аналитик;"
            },
            {
                "id": 2,
                "answer": "санитарка-мойщица;"
            },
            {
                "id": 3,
                "answer": "уполномоченный по качеству."
            }
        ],
        "isSingleResponce": true,
        "idCorrectAnswer": [3]
    },
    {
        "id": 45,
        "topic": "Глава 2. Основы системы управления качеством в аптеке",
        "roleTest": "NO_ROLE",
        "question": "Могут ли условия хранения и транспортирования привести к отклонениям показателей качества лекарственных препаратов от установленных норм?",
        "responseOptions": [
            {
                "id": 0,
                "answer": "могут при несоблюдении требований к правилам, срокам и условий хранения и транспортирования;"
            },
            {
                "id": 1,
                "answer": "могут при несоблюдении требований к правилам, срокам и условий хранения, но транспортирование не оказывает влияние на качество лекарственных препаратов;"
            },
            {
                "id": 2,
                "answer": "не могут при хранении, но могут при транспортировании;"
            },
            {
                "id": 3,
                "answer": "не могут, так как все складские помещения проходят проверку при лицензировании."
            }
        ],
        "isSingleResponce": true,
        "idCorrectAnswer": [0]
    },
    {
        "id": 46,
        "topic": "Глава 2. Основы системы управления качеством в аптеке",
        "roleTest": "NO_ROLE",
        "question": "Обязаны ли фармацевтические работники гарантировать квалифицированное обслуживание пациенту?",
        "responseOptions": [
            {
                "id": 0,
                "answer": "не обязаны;"
            },
            {
                "id": 1,
                "answer": "обязаны;"
            },
            {
                "id": 2,
                "answer": "обязаны в случае заключения контракта с пациентом;"
            },
            {
                "id": 3,
                "answer": "обязаны, только для пациентов, находящихся в стационарах."
            }
        ],
        "isSingleResponce": true,
        "idCorrectAnswer": [1]
    },
    {
        "id": 47,
        "topic": "Глава 2. Основы системы управления качеством в аптеке",
        "roleTest": "NO_ROLE",
        "question": "Ответственным за качество работы аптечной организации является",
        "responseOptions": [
            {
                "id": 0,
                "answer": "исполнитель;"
            },
            {
                "id": 1,
                "answer": "провизор-аналитик;"
            },
            {
                "id": 2,
                "answer": "руководитель аптечной организации;"
            },
            {
                "id": 3,
                "answer": "уполномоченный по качеству, назначенный руководителем предприятия."
            }
        ],
        "isSingleResponce": true,
        "idCorrectAnswer": [2]
    },
    {
        "id": 48,
        "topic": "Глава 2. Основы системы управления качеством в аптеке",
        "roleTest": "NO_ROLE",
        "question": "Относится ли фармацевтический работник, участвующий в изготовлении лекарственного препарата, к субъектам внутреннего контроля качества в аптечной организации?",
        "responseOptions": [
            {
                "id": 0,
                "answer": "не относится, так как его действия проверяет провизор-технолог;"
            },
            {
                "id": 1,
                "answer": "не относится, так как контроль должен проводить провизор-аналитик;"
            },
            {
                "id": 2,
                "answer": "относится, если проверяет работу другого сотрудника;"
            },
            {
                "id": 3,
                "answer": "относится, так как обязан проводить самоконтроль выполняемой им процедуры."
            }
        ],
        "isSingleResponce": true,
        "idCorrectAnswer": [3]
    },
    {
        "id": 49,
        "topic": "Глава 2. Основы системы управления качеством в аптеке",
        "roleTest": "NO_ROLE",
        "question": "Под контролем качества работы предприятия подразумевают",
        "responseOptions": [
            {
                "id": 0,
                "answer": "комплекс мероприятий, направленных на предупреждение отклонений показателей качества от утвержденных норм;"
            },
            {
                "id": 1,
                "answer": "обеспеченность предприятия ресурсами для качественного выполнения порученной работы;"
            },
            {
                "id": 2,
                "answer": "проверку показателей качества на соответствие утвержденным нормам;"
            },
            {
                "id": 3,
                "answer": "разработку документации по управлению качеством."
            }
        ],
        "isSingleResponce": true,
        "idCorrectAnswer": [2]
    },
    {
        "id": 50,
        "topic": "Глава 2. Основы системы управления качеством в аптеке",
        "roleTest": "NO_ROLE",
        "question": "Под обеспечением качества работы предприятия подразумевают",
        "responseOptions": [
            {
                "id": 0,
                "answer": "комплекс мероприятий, направленных на предупреждение отклонений показателей качества от утвержденных норм;"
            },
            {
                "id": 1,
                "answer": "обеспеченность предприятия ресурсами для качественного выполнения порученной работы;"
            },
            {
                "id": 2,
                "answer": "проверку показателей качества на соответствие утвержденным нормам;"
            },
            {
                "id": 3,
                "answer": "разработку документации по управлению качеством."
            }
        ],
        "isSingleResponce": true,
        "idCorrectAnswer": [0]
    },
    {
        "id": 51,
        "topic": "Глава 2. Основы системы управления качеством в аптеке",
        "roleTest": "NO_ROLE",
        "question": "Политика и цели в области качества аптечной организации представляет собой",
        "responseOptions": [
            {
                "id": 0,
                "answer": "документ, отражающий общие намерения и направления деятельности аптечной организации, включая цели, необходимые для достижения и задачи, требующие решения;"
            },
            {
                "id": 1,
                "answer": "документ, подробно описывающий систему управления качеством предприятия и мероприятия, необходимые для решения поставленных задач и достижения целей политики качества;"
            },
            {
                "id": 2,
                "answer": "документ, разработанный государственными уполномоченными органами или вышестоящей организацией, регламентирующие нормы, правила в области системы управления качеством;"
            },
            {
                "id": 3,
                "answer": "документ, разработанный уполномоченными лицами аптечной организации, регламентирующий нормы, правила и порядок в области системы управления качеством на предприятии."
            }
        ],
        "isSingleResponce": true,
        "idCorrectAnswer": [0]
    },
    {
        "id": 52,
        "topic": "Глава 2. Основы системы управления качеством в аптеке",
        "roleTest": "NO_ROLE",
        "question": "Предметом внутреннего контроля в аптечной организации могут быть",
        "responseOptions": [
            {
                "id": 0,
                "answer": "качество сырья, полупродуктов, лекарственных препаратов, изготовленных в аптеке;"
            },
            {
                "id": 1,
                "answer": "мотивация персонала;"
            },
            {
                "id": 2,
                "answer": "надлежащее выполнение процессов и стандартных процедур норм;"
            },
            {
                "id": 3,
                "answer": "соблюдение персоналом установленных санитарно-гигиенических и квалификационных."
            }
        ],
        "isSingleResponce": false,
        "idCorrectAnswer": [0, 2, 3]
    },
    {
        "id": 53,
        "topic": "Глава 2. Основы системы управления качеством в аптеке",
        "roleTest": "NO_ROLE",
        "question": "Предполагают ли основные принципы международного стандарта надлежащей аптечной практики GPP максимально тесное взаимодействие врача и работника аптечного учреждения?",
        "responseOptions": [
            {
                "id": 0,
                "answer": "допустимо, но только с разрешения пациента;"
            },
            {
                "id": 1,
                "answer": "не предполагают"
            },
            {
                "id": 2,
                "answer": "подобное взаимодействие не допустимо;"
            },
            {
                "id": 3,
                "answer": "предполагают обязательное взаимодействие."
            }
        ],
        "isSingleResponce": true,
        "idCorrectAnswer": [3]
    },
    {
        "id": 54,
        "topic": "Глава 2. Основы системы управления качеством в аптеке",
        "roleTest": "NO_ROLE",
        "question": "Провизоры аптечной организации должны выполнять трудовые функции в соответствии со следующими профессиональными стандартами",
        "responseOptions": [
            {
                "id": 0,
                "answer": "профессиональный стандарт «Провизор-аналитик», утвержденный приказом Министерства труда и социальной защиты Российской Федерации от 22 мая 2017 г. № 427н;"
            },
            {
                "id": 1,
                "answer": "профессиональный стандарт «Провизор», утвержденный приказом Министерства труда и социальной защиты Российской Федерации от 9 марта 2016 г. № 91н;"
            },
            {
                "id": 2,
                "answer": "профессиональный стандарт «Специалист в области управления фармацевтической деятельностью», утвержденный приказом Министерства труда и социальной защиты Российской Федерации от 22 мая 2017 г. № 428н;"
            },
            {
                "id": 3,
                "answer": "профессиональный стандарт «Специалист по промышленной фармации в области производства лекарственных средств», утвержденный приказом Министерства труда и социальной защиты Российской Федерации от 22 мая 2017 г. № 430н."
            }
        ],
        "isSingleResponce": false,
        "idCorrectAnswer": [0, 1, 2]
    },
    {
        "id": 55,
        "topic": "Глава 2. Основы системы управления качеством в аптеке",
        "roleTest": "NO_ROLE",
        "question": "Руководство по качеству аптечной организации представляет собой",
        "responseOptions": [
            {
                "id": 0,
                "answer": "документ, отражающий общие намерения и направления деятельности аптечной организации, включая цели, необходимые для достижения и задачи, требующие решения;"
            },
            {
                "id": 1,
                "answer": "документ, подробно описывающий систему управления качеством предприятия и мероприятия, необходимые для решения поставленных задач и достижения целей политики качества;"
            },
            {
                "id": 2,
                "answer": "документ, разработанный государственными уполномоченными органами или вышестоящей организацией, регламентирующие нормы, правила в области системы управления качеством;"
            },
            {
                "id": 3,
                "answer": "документ, разработанный уполномоченными лицами аптечной организации, регламентирующий нормы, правила и порядок в области системы управления качеством на предприятии."
            }
        ],
        "isSingleResponce": true,
        "idCorrectAnswer": [1]
    },
    {
        "id": 56,
        "topic": "Глава 2. Основы системы управления качеством в аптеке",
        "roleTest": "NO_ROLE",
        "question": "Система внутреннего аудита и самоинспекции в аптечной организации является",
        "responseOptions": [
            {
                "id": 0,
                "answer": "задачей системы управления качеством;"
            },
            {
                "id": 1,
                "answer": "механизмом обеспечения качества;"
            },
            {
                "id": 2,
                "answer": "результатом оценки системы управления качеством;"
            },
            {
                "id": 3,
                "answer": "целью системы управления качеством."
            }
        ],
        "isSingleResponce": true,
        "idCorrectAnswer": [1]
    },
    {
        "id": 57,
        "topic": "Глава 2. Основы системы управления качеством в аптеке",
        "roleTest": "NO_ROLE",
        "question": "Система обеспечения качества в аптечной организации охватывает",
        "responseOptions": [
            {
                "id": 0,
                "answer": "качество оказания услуг посетителям;"
            },
            {
                "id": 1,
                "answer": "качество оформления внешней регламентирующей документации;"
            },
            {
                "id": 2,
                "answer": "качество реализуемых товаров аптечного ассортимента;"
            },
            {
                "id": 3,
                "answer": "качество условий хранения лекарственных препаратов и других товаров аптечного ассортимента."
            }
        ],
        "isSingleResponce": false,
        "idCorrectAnswer": [0, 2, 3]
    },
    {
        "id": 58,
        "topic": "Глава 2. Основы системы управления качеством в аптеке",
        "roleTest": "NO_ROLE",
        "question": "Система фармаконадзора предполагает",
        "responseOptions": [
            {
                "id": 0,
                "answer": "контроль за работой фармацевтических работников;"
            },
            {
                "id": 1,
                "answer": "надзор уполномоченных органов за фармацевтической деятельность аптечной организации;"
            },
            {
                "id": 2,
                "answer": "регистрацию нежелательных побочных явлений при применении лекарственных препаратов и информирование надзорных органов о зарегистрированных фактах;"
            },
            {
                "id": 3,
                "answer": "самоинспекцию в аптечной организации."
            }
        ],
        "isSingleResponce": true,
        "idCorrectAnswer": [2]
    },
    {
        "id": 59,
        "topic": "Глава 2. Основы системы управления качеством в аптеке",
        "roleTest": "NO_ROLE",
        "question": "Составляющими системы управления качеством в аптечной организации являются",
        "responseOptions": [
            {
                "id": 0,
                "answer": "документооборот качества;"
            },
            {
                "id": 1,
                "answer": "контроль качества;"
            },
            {
                "id": 2,
                "answer": "обеспечение качества;"
            },
            {
                "id": 3,
                "answer": "персонал."
            }
        ],
        "isSingleResponce": false,
        "idCorrectAnswer": [1, 2]
    },
    {
        "id": 60,
        "topic": "Глава 2. Основы системы управления качеством в аптеке",
        "roleTest": "NO_ROLE",
        "question": "Стандарт ISO «Системы менеджмента качества. Основные положения и словарь» представлен серией",
        "responseOptions": [
            {
                "id": 0,
                "answer": "ISO 9000:2000;"
            },
            {
                "id": 1,
                "answer": "ISO 9001:2000;"
            },
            {
                "id": 2,
                "answer": "ISO 9004:2000;"
            },
            {
                "id": 3,
                "answer": "ISO 9011:2000."
            }
        ],
        "isSingleResponce": true,
        "idCorrectAnswer": [0]
    },
    {
        "id": 61,
        "topic": "Глава 2. Основы системы управления качеством в аптеке",
        "roleTest": "NO_ROLE",
        "question": "Уполномоченное лицо по качеству подчиняется",
        "responseOptions": [
            {
                "id": 0,
                "answer": "только Росздравнадзору;"
            },
            {
                "id": 1,
                "answer": "только провизору-аналитику;"
            },
            {
                "id": 2,
                "answer": "только провизору-технологу;"
            },
            {
                "id": 3,
                "answer": "только руководителю организации."
            }
        ],
        "isSingleResponce": true,
        "idCorrectAnswer": [3]
    },
    {
        "id": 62,
        "topic": "Глава 2. Основы системы управления качеством в аптеке",
        "roleTest": "NO_ROLE",
        "question": "Федеральный государственный надзор в сфере обращения лекарственных средств в Российской Федерации осуществляется на основании",
        "responseOptions": [
            {
                "id": 0,
                "answer": "Федерального закона от 12.04.2010 № 61-ФЗ «Об обращении лекарственных средств»;"
            },
            {
                "id": 1,
                "answer": "Федерального закона от 4 мая 2011 года № 99-ФЗ «О лицензировании отдельных видов деятельности»;"
            },
            {
                "id": 2,
                "answer": "постановления Правительства РФ от 15.10.2012 № 1043 «Об утверждении Положения о федеральном государственном надзоре в сфере обращения лекарственных средств»;"
            },
            {
                "id": 3,
                "answer": "приказа Минпромторга России от 14.06.2013 № 916 «Об утверждении Правил надлежащей производственной практики»."
            }
        ],
        "isSingleResponce": true,
        "idCorrectAnswer": [2]
    },
    {
        "id": 63,
        "topic": "Глава 2. Основы системы управления качеством в аптеке",
        "roleTest": "NO_ROLE",
        "question": "Целью деятельности организации международной организации по стандартизации International Organization for Standardization (ISO) является",
        "responseOptions": [
            {
                "id": 0,
                "answer": "введение общих требований к выполнению процессов при товарообмене;"
            },
            {
                "id": 1,
                "answer": "исключение из экономического сообщества государств, не соблюдающих требования стандартов ISO;"
            },
            {
                "id": 2,
                "answer": "разработка единых стандартов в области интеллектуальной, научной, технической и экономической деятельности;"
            },
            {
                "id": 3,
                "answer": "содействие развитию стандартизации в мировом масштабе для облегчения международного товарообмена и взаимопомощи, а также для расширения сотрудничества в области интеллектуальной, научной, технической и экономической деятельности."
            }
        ],
        "isSingleResponce": true,
        "idCorrectAnswer": [3]
    },
    {
        "id": 64,
        "topic": "Глава 2. Основы системы управления качеством в аптеке",
        "roleTest": "NO_ROLE",
        "question": "Целью создания системы управления качеством является комплекс",
        "responseOptions": [
            {
                "id": 0,
                "answer": "дисциплинарных мер по отношению к персоналу;"
            },
            {
                "id": 1,
                "answer": "жесткого контроля качества лекарственных средств, реализуемых в аптеке;"
            },
            {
                "id": 2,
                "answer": "мероприятий обеспечения качества лекарственных средств, реализуемых в аптеке;"
            },
            {
                "id": 3,
                "answer": "обеспечения и контроля качества, гарантирующий обеспечение населения качественными, эффективными и безопасными товарами аптечного ассортимента."
            }
        ],
        "isSingleResponce": true,
        "idCorrectAnswer": [3]
    }
]