import React from "react";
import {
  Grid,
  Typography
} from "@mui/material";

export const StartTestPage = () => {

  const handleChange = () => {
    window.location.assign("/test");
  };

  return (
    <Grid container direction="column" alignItems="center" spacing={3}>
      <Grid item marginTop="12pt" />
      <Grid item direction="row">
        <Typography align="center" fontSize="34pt" mt="4">
          Безопасность медицинской деятельности в аптеке 
        </Typography>
      </Grid>

      <Grid item direction="row">
        <Typography align="center">
          В тестах представлены основные разделы безопасности пациентов при оказании специализированной фармакологической помощи. 
          Разделы тестов соответствуют содержанию основных профессиональных образовательных программ высшего образования – 
          подготовке кадров высшей квалификации в ординатуре и дополнительной профессиональной программе профессиональной 
          переподготовки фармакологов по специальностям «Клиническая фармакология». 
          Тесты предназначены для руководителей медицинских организаций, 
          специалистов по организации здравоохранения воохранения, практикующих фармакологов.
        </Typography>
      </Grid>

      <Grid container justifyContent="center" direction="row" marginTop="16pt">
        <button
          type="button"
          className="btn btn-light"
          style={{ backgroundColor: "#058877", color: "white" }}
          onClick={handleChange}
        >
          Начать тестирование!
        </button>
      </Grid>

    </Grid>
  );
};
